<template>
  <div class="checkout-page-container" ref="wrapper">
    <div class="checkout__main-body">
      <template v-if="error">
        <div class="title error">
          {{
            this.order === undefined
              ? "网络错误！请返回订单重新下单"
              : "支付失败！"
          }}
        </div>
        <router-link
          tag="div"
          class="bt"
          :to="{
            path: '/orders'
          }"
          >返回订单列表</router-link
        >
      </template>
      <template v-else>
        <div class="title">收银台</div>
        <p class="trade_no" v-if="order">订单号：{{ order.trade_num }}</p>
        <p class="total_fee" v-if="order">
          实付金额：￥{{ order.total_price }}
        </p>
        <div class="pay-qr-wrapper">
          <div v-if="maskHint" class="pay-hint-text">
            <p v-html="maskHint"></p>
          </div>
          <qr-code
            class="qrbox"
            v-if="qr"
            :text="qr"
            :timelimit="Number(order.invalid_at)"
            :refresh="requesting"
            @onRefresh="onRefresh"
          ></qr-code>
        </div>
        <p class="hint">使用微信扫描此二维码进行支付，请勿刷新页面</p>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "page-wx-checkout",
  data() {
    const route = this.$route;
    return {
      order: route.params.order,
      qr: route.params.qr,
      height: window.innerHeight,
      requesting: false,
      polling: null,
      maskHint: false
    };
  },
  computed: {
    error() {
      return this.order === undefined || this.qr === undefined;
    }
  },
  watch: {
    order: {
      handler(val) {
        const that = this;
        if (val) {
          that.polling = window.setInterval(() => {
            that.checkStatus();
          }, 500);
        }
      },
      immediate: true
    }
  },
  mounted() {
    const that = this;
    that.heightFullScreen(that.$refs.wrapper, false);
  },
  methods: {
    onRefresh() {
      const that = this;
      that.requesting = true;
      that.$request
        .get("/api/user/order/pay", {
          tradeno: that.order.trade_num,
          renew: 1
        })
        .then(res => {
          const data = res.data;
          that.requesting = false;
          that.order.invalid_at = data.order.invalid_at;
          that.qr = data.code_url;
        });
    },
    checkStatus() {
      const that = this;
      that.$request
        .get("/api/account/order-check", {
          trade_num: that.order.trade_num
        })
        .then(res => {
          const status = res.data;
          if (status === "SUCCESS") {
            that.maskHint = "支付成功,<br/>页面即将跳转...";
            // 结束 interval
            window.clearInterval(that.polling);
            // 跳转
            setTimeout(() => {
              that.maskHint = false;
              that.$router.push({
                path: `/order?out_trade_no=${that.order.trade_num}`
              });
            }, 1500);
          } else if (status === "NOTPAY") {
            // 创建了 还没付
          } else if (status === "USERPAYING") {
            // 扫了 还没付
            that.maskHint = "请在手机上完成支付";
          }
        })
        .catch(e => {
          console.log(e);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.pay-qr-wrapper {
  position: relative;
  .pay-hint-text {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 3;
    p {
      font-size: 14px;
      color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      line-height: 1.5;
      white-space: nowrap;
    }
  }
}
</style>
